<template>
  <div>
    <KTCard ref="preview" v-bind:title="title">
      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          对账统计
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <ul class="nav nav-pills nav-pills-sm nav-dark-75">
            <li class="nav-item">
              <a data-toggle="tab" @click="changeRecent(7)"
                :class="[rencent == 7 ? 'nav-link py-2 px-4 active' : 'nav-link py-2 px-4 ']">
                近一周
              </a>
            </li>
            <li class="nav-item">
              <a data-toggle="tab" @click="changeRecent(30)"
                :class="[rencent == 30 ? 'nav-link py-2 px-4 active' : 'nav-link py-2 px-4 ']"> 近一月 </a>
            </li>
          </ul>
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 70vh">
          <!--表格-->
          <v-data-table v-model="table.selected" :headers="table.headers" :items="table.desserts"
            :single-select="table.singleSelect" :footer-props="table.footer" :server-items-length="table.itemsCount"
            :options.sync="table.pagination" :no-data-text="table.noDataText" :loading="table.loading"
            :loading-text="table.loadingText" item-key="customerId" show-select>

            <!--表格筛选-->
            <template v-slot:top>
              <b-form inline>

                <b-form-group class="mr-1 ml-md-1 ml-lg-1 ml-xl-1 ml-sm-0 ml-0">
                  <b-form-datepicker locale="zh" :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }" v-model="filter.startTime" placeholder="起始日期"></b-form-datepicker>
                </b-form-group>

                <label class="after-label ml-2 pb-md-2 pb-lg-2 pb-xl-2 pb-sm-3 pb-3">-</label>
                <b-form-group class="mx-2">
                  <b-form-datepicker locale="zh" :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }" v-model="filter.endTime" placeholder="结束日期"></b-form-datepicker>
                </b-form-group>

                <b-button variant="success" v-on:click="getData()">
                  <v-icon small>
                    flaticon-search
                  </v-icon>
                  查询
                </b-button>
              </b-form>

              <!--分隔线-->
              <v-divider></v-divider>
            </template>
          </v-data-table>
        </div>
      </template>
    </KTCard>

  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import ApiService from "@/core/services/api.service";
import Moment from "moment";

export default {
  name: "OrderReport",
  components: {
    KTCard,
  },
  props: {
    title: String
  },
  data() {
    return {
      rencent: 7,
      filter: {
        startTime: Moment(new Date(Date.now() - (7 * 24 * 60 * 60 * 1000))).format("YYYY-MM-DD"),
        endTime: Moment(new Date()).format("YYYY-MM-DD")
      },
      table: {
        loadingText: "正在加载...",
        noDataText: "暂无数据...",
        loading: false,
        singleSelect: false,
        selected: [],
        itemsCount: 10,
        headers: [{
          text: "日期",
          align: "left",
          value: "date"
        }, {
          text: "订单总金额",
          value: "totalAmount"
        },
        {
          text: "订单总量",
          value: "totalCount"
        },
        {
          text: "成功金额",
          value: "successAmount"
        },
        {
          text: "成功数量",
          value: "successCount"
        },
        {
          text: "失败金额",
          value: "failAmount"
        },
        {
          text: "失败数量",
          value: "failCount"
        },
        {
          text: "成功率(%)",
          value: "successRate"
        },
        ],
        footer: {
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          itemsPerPageText: "分页数量",
          showCurrentPage: true
        },
        pagination: {},
        desserts: []
      },
    };
  },
  mounted() {

  },
  created() {

  },
  methods: {

    getData() {
      let startTime = this.filter.startTime, endTime = this.filter.endTime;
      if (startTime) {
        startTime = startTime + " 00:00:00";
      }
      if (endTime) {
        endTime = endTime + " 23:59:59";
      }
      let params = {
        "merchantId": this.filter.merchantId,
        "startTime": startTime,
        "endTime": endTime
      };

      ApiService.query("/api/report/order", {
        params: params
      }).then(({
        data
      }) => {
        this.table.desserts = data;
      })
    },
    changeRecent(days) {
      this.filter.startTime = Moment(new Date(Date.now() - (days * 24 * 60 * 60 * 1000))).format("YYYY-MM-DD");
      this.filter.endTime = Moment(new Date()).format("YYYY-MM-DD");
      this.rencent = days;
      this.getData();
    },

    getSelectedIds() {
      let ids = new Array();
      if (this.table.selected != null && this.table.selected != undefined) {
        this.table.selected.forEach(function (item) {
          ids.push(item.customerId);
        })
      }
      return ids;
    }
  },
  computed: {
    pagination: function () {
      return this.table.pagination
    }
  },
  watch: {
    pagination: {
      handler() {
        this.getData()
      },
      deep: true,
    }
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>